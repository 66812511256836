import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"

const HomePage = ({ data: { wpPage } }) => {
  const { featuredImage, smartcrawlSeo } = wpPage
  return (
    <Layout>
      <Seo
        title={smartcrawlSeo?.title}
        description={smartcrawlSeo?.metaDesc}
        ogTitle={smartcrawlSeo?.opengraphTitle}
        ogDescription={smartcrawlSeo?.opengraphDescription}
        twTitle={smartcrawlSeo?.twitterTitle}
        twDescription={smartcrawlSeo?.twitterDescription}
        featuredImage={featuredImage?.node?.mediaItemUrl}
        ogImage={smartcrawlSeo?.opengraphImage?.mediaItemUrl}
        twImage={smartcrawlSeo?.twitterImage?.mediaItemUrl}
      />
      <Helmet>
        <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org/",
                "@type": "Organization",
                "url": "https://centerforinquiry.org/",
                "name": "Center for Inquiry ",
                "description": "The Center for Inquiry (CFI) is a 501(c)(3) charitable nonprofit organization dedicated to defending science and critical thinking in examining religion. CFI’s vision is a world in which evidence, science, and compassion—rather than superstition, pseudoscience, or prejudice—guide public policy.",
                "@id": "https://centerforinquiry.org/#organization/",
                "logo": "https://translationsproject.org/static/5b8e5d5a96fdd64b0bb44c7de1015d52/f2ee4/translations-project-logo-desktop-768x240.webp",
                "sameAs": [
                    "https://www.facebook.com/centerforinquiry/",
                    "https://twitter.com/center4inquiry"
                ],
                "subOrganization": [
                    {
                        "@type": "Organization",
                        "@id": "https://translationsproject.org/#organization",
                        "name": "The Translations Project" 
                    }
                ]
              }     
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
                "@context": "http://schema.org",
                "@type": "WebSite",
                "name": "Translations Project",
                "@id": "https://translationsproject.org/#website",
                "url": "https://translationsproject.org/",
                "isAccessibleForFree": true,
                "sourceOrganization": 
                {
                    "@type": "Organization",
                    "@id": "https://centerforinquiry.org/#organization",
                    "name": "Center for Inquiry" 
                }
              }     
          `}
        </script>
      </Helmet>
      <article className="page hentry home">
        <div className="entry-content">
          <StaticImage
            loading="lazy"
            layout="fullWidth"
            placeholder="blurred"
            src="../images/egyptian-surgery.jpeg"
            className="alignfull"
            objectFit="cover"
          />
          <div className="overlay">
            <div
              style={{ height: "50px" }}
              aria-hidden="true"
              className="wp-block-spacer"
            ></div>
            <div className="wp-block-columns alignwide medium-width has-tp-orange-background-color has-text-color has-background">
              <div className="wp-block-column">
                <h1
                  id="tst"
                  className="has-text-align-center medium-width has-white-color"
                >
                  Choose Your Language
                </h1>
              </div>
            </div>
            <div className="wp-block-columns medium-width pb-3 shadow has-tp-orange-background-color has-background">
              {/* flex row/col */}
              <div class="lang-row">
                <div class="column-dbl">
                  <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-3 wp-block-buttons mb-3">
                    <div className="wp-block-button large is-style-fill">
                      <Link
                        className="wp-block-button__link has-white-background-color has-text-color  has-background"
                        to="/arabic/"
                        style={{ color: "#ea5213" }}
                      >
                        العربية
                        <span className="small">(Arabic)</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="column-dbl">
                  <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-3 wp-block-buttons mb-3">
                    <div className="wp-block-button large is-style-fill">
                      <Link
                        className="wp-block-button__link has-white-background-color has-text-color  has-background"
                        to="/farsi/"
                        style={{ color: "#ea5213" }}
                      >
                        <strong>فارسی</strong>{" "}
                        <span className="small">(Farsi)</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="column-dbl">
                  <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-3 wp-block-buttons mb-3">
                    <div className="wp-block-button large is-style-fill">
                      <Link
                        className="wp-block-button__link has-white-background-color has-text-color  has-background"
                        to="/urdu/"
                        style={{ color: "#ea5213" }}
                      >
                        اردو
                        <span className="small">(Urdu)</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="column-dbl">
                  <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-3 wp-block-buttons mb-3">
                    <div className="wp-block-button large is-style-fill">
                      <Link
                        className="wp-block-button__link has-white-background-color has-text-color  has-background"
                        to="/indonesian/"
                        style={{ color: "#ea5213" }}
                      >
                        Bahasa Indonesia
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="column-single">
                  <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-3 wp-block-buttons mb-3">
                    <div className=" langbox wp-block-button large is-style-fill">
                      <Link
                        className="wp-block-button__link has-white-background-color has-text-color  has-background"
                        to="/hindi/"
                        style={{ color: "#ea5213" }}
                      >
                        हिंदी
                        <span className="small">(Hindi)</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* A flex row/col */}
            </div>

            <div
              style={{ height: "50px" }}
              aria-hidden="true"
              className="wp-block-spacer"
            ></div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query WordPressHomePage {
    wpPage(isFrontPage: { eq: true }) {
      title
      content
      databaseId
      uri
      slug
      smartcrawlSeo {
        metaDesc
        title
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
  }
`
